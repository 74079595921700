/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 23번째 로그에서는 만우절, 일본의 새 연호, 북스캔에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.reddit.com/r/programming/comments/b7ojga/the_stackoverflow_april_fools/"
  }, "The stackoverflow April fools : programming")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/mitchellh/status/1112761803489071106"
  }, "Mitchell Hashimoto on Twitter: \"Terraform is finally achieving its true purpose. ...\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ndmckinley.github.io/terraform-provider-dominos/"
  }, "Provider Purpose | terraform-provider-dominos")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://e8y.net/blog/2006/07/25/p126.html"
  }, "(일본어) 구글에서 \"배고프다\"고 검색하면 피자가 배달되도록 하기까지")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bitcointalk.org/index.php?topic=137.0"
  }, "Pizza for bitcoins?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/zozi009/status/1112369039978827776?s=20"
  }, "zozi(厳島神社の人） on Twitter: \"There was a talk about delivery by drone, ...\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=Zjp5esYAltU"
  }, "빙그레 붕어싸만코 광고 - TV CF 싸만코의 역습 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.raywenderlich.com/3080-why-i-m-ditching-ios-and-becoming-an-android-developer"
  }, "Why I’m Ditching iOS and Becoming an Android Developer | raywenderlich.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.hani.co.kr/arti/society/society_general/630754.html"
  }, "만우절 기념 구글 두들이 ‘네이버 로고’? : 사회일반 : 사회 : 뉴스 : 한겨레")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=rsJlT53jU_4"
  }, "Bixby Speaker Impressions! - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/1111-warp-better-vpn/"
  }, "Introducing Warp: Fixing Mobile Internet Performance and Security")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Deep_packet_inspection"
  }, "Deep packet inspection - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/23428946#none"
  }, "“헤이세이 불황 시대 끝났다” 일본 새 연호 ‘레이와’ 환호")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://qiita.com/uniqode/items/ac2edcfb2b01b0bbc569"
  }, "(일본어) 새로운 연호는 이걸로 결정 - Qiita")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/unicode-range"
  }, "unicode-range - CSS: Cascading Style Sheets | MDN")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/chisaka_kr/status/1112640219025563648"
  }, "chisaka on Twitter: \"이번 신연호(레이와)대응 꿀잼포인트… \"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/ruby/www.ruby-lang.org/pull/2023"
  }, "Ruby 2.6.3 released by nurse · Pull Request #2023 · ruby/www.ruby-lang.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://unicode.org/versions/Unicode12.1.0/"
  }, "Unicode 12.1.0")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.fujitsu.com/kr/products/computing/peripheral/scanners/scansnap/ix500/"
  }, "FUJITSU Image Scanner ScanSnap iX500 - Fujitsu Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://prod.danawa.com/info/?pcode=969992"
  }, "현대오피스 YG-858 A4 종합정보 행복쇼핑의 시작 ! 다나와 (가격비교) - Danawa.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://speakerdeck.com/nacyot/write-the-docs-seoul-meetup-number-1-explorable-explanations"
  }, "Write the Docs Seoul Meetup #1: Explorable Explanations - Speaker Deck")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://acrobat.adobe.com/us/en/acrobat/acrobat-pro.html"
  }, "Adobe Acrobat Pro DC: PDF converter, convert PDFs from anywhere.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bbc.com/korean/news-45002002"
  }, "혹시 끊임없이 책을 사지만, 절대 읽지 않고 있나요? - BBC News 코리아")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/AppleScript"
  }, "AppleScript - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/vision/docs/ocr"
  }, "Detect Text (OCR)  |  Cloud Vision API Documentation  |  Google Cloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/textract/"
  }, "Amazon Textract | 텍스트 및 데이터 추출 | AWS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mi.com/global/mix/"
  }, "Mi Global Home")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.goodreader.com/"
  }, "GoodReader")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://readdle.com/en/documents"
  }, "Documents")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/search?q=%EB%B8%8C%EB%9F%B0%EC%B9%98%20%EB%B3%B5%EC%82%AC&src=typd"
  }, "브런치 복사 - Twitter Search")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.fujitsu.com/kr/products/computing/peripheral/scanners/scansnap/sv600/"
  }, "FUJITSU Image Scanner ScanSnap SV600 - Fujitsu Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2007/12/06/google-books-adds-hand-scans/"
  }, "Google Books Adds Hand Scans | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.czur.com/"
  }, "Smart & Professional Book Scanner with Digitization Solutions - CZUR")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.wadiz.kr/web/campaign/detail/30955"
  }, "300페이지를 20분 안에 스캔한다고? 스마트스캐너: 아우라 | 와디즈 리워드 - 크라우드펀딩")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=03ccxwNssmo"
  }, "BFS-Auto: High Speed Book Scanner at over 250 pages/min - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.sbs.co.kr/news/endPage.do?news_id=N1003990211"
  }, "단독 - 1년에 무려 8만 장…서울대 '스캔 노예' 파문")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Aaron_Swartz"
  }, "Aaron Swartz - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.oreilly.com/"
  }, "O'Reilly Media - Technology and Business Training")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pastebin.com/fSrwaeyg"
  }, "세계문학APP - 오픈파트너 모집 당시 등록된 주소로 대량 발송되는 공지 메일입니다. - Pastebin.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ridibooks.com/legal/terms"
  }, "이용약관 제7조 서비스의 중단 - 리디")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
